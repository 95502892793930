r
<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="
        login login-1
        d-flex
        flex-column flex-lg-row flex-column-fluid
        bg-white
      "
      :class="{
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Content-->
      <div
        class="
          login-content
          flex-row-fluid
          d-flex
          flex-column
          justify-content-center
          position-relative
          overflow-hidden
          p-7
          mx-auto
        "
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="
                    font-weight-bolder
                    text-dark
                    font-size-h4 font-size-h1-lg
                  "
                >
                  2 Factor Verification
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  We've send the One Time Password (OTP) to your device. Please
                  check email/sms and enter it below.
                </p>
              </div>
              <base-input
                extra-class=" h-auto py-7 px-6"
                v-model="formOtp.otp"
                hide-label
                label-class="d-none"
                label="Username"
                :validator="$v.formOtp.otp"
                :messages="localMessages"
              ></base-input>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  @click.prevent="onOtp"
                  class="
                    btn btn-primary
                    font-weight-bolder font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                  "
                >
                  Submit Otp
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="
                    btn btn-light-primary
                    font-weight-bolder font-size-h6
                    px-8
                    py-4
                    my-3
                  "
                  @click="onCancel"
                >
                  Back to Login
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
// FormValidation plugins
import KTUtil from "@/assets/js/components/util";
import {
  LOGOUT,
  OTP_EMAIL_VALIDATION,
  OTP_SMS_VALIDATION,
} from "@/core/services/store/modules/auth.module";
import { validationMixin } from "vuelidate";
import {
  // eslint-disable-next-line no-unused-vars
  email,
  required,
} from "vuelidate/lib/validators";
import { LOGIN_CONFIG } from "@/core/config/config";

export default {
  mixins: [validationMixin],
  data() {
    return {
      loginChannelId: LOGIN_CONFIG.LOGIN_CHANNEL_ID, // put in the system settings
      state: "forgot",
      // Remove this dummy login info
      formOtp: {
        otp: "232323",
      },
    };
  },
  validations: {
    formOtp: {
      otp: { required },
    },
  },
  computed: {
    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {},
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },
    onOtp() {
      console.log("clicked");
      this.$v.formOtp.$touch();
      if (this.$v.formOtp.$anyError) {
        return;
      } else {
        let otp = this.formOtp.otp;
        let payload = {
          otp_number: otp,
        };
        if (LOGIN_CONFIG.LOGIN_CHANNEL_ID === 3) {
          this.$store
            .dispatch(OTP_SMS_VALIDATION, payload)
            // go to which page after successfully login
            .then((result) => {
              console.log(result);
              if (result.response_code === 2100) {
                this.$router.replace({ name: "dashboard" });
              }
            })
            .catch(() => {});
        } else {
          this.$store
            .dispatch(OTP_EMAIL_VALIDATION, payload)
            // go to which page after successfully login
            .then((result) => {
              console.log(result);
              if (result.response_code === 2100) {
                this.$router.replace({ name: "dashboard" });
              }
            })
            .catch(() => {});
        }
      }
    },
    onCancel() {
      this.$store.dispatch(LOGOUT);
    },
  },
};
</script>
